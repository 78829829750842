import styled, { css } from "styled-components";
import icoInfo from './images/ico-info.svg';
import { colors } from './../../variables';
import { RouteComponentProps, withRouter } from "react-router-dom";

export const GateMask = styled.div<{active?: boolean}>`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.85);
  z-index: 20;
  display: none;
  @media (max-width: 991px){
    
  }
  ${props => props.active && css`
    display: block;
  `}
`;
export const GateContainer = styled.div<{active?: boolean}>`
  position: absolute;
  background-color: #fff;
  border-radius: 5px;
  width: 450px;
  left: 50%;
  margin-left: -225px;
  margin-top: 150px;
  z-index: 21;
  display: none;
  @media (max-width: 991px){
    width: 90%;
    margin-left: -45%;
    margin-top: 20px;
  }
  ${props => props.active && css`
    display: block;
  `}
`;

export const GateContent = styled.div`
  position: relative;
  padding: 40px 30px;
  text-align: center;
  color: #000;
  @media (max-width: 991px){
    
  }
`;

export const GateTitle = styled.div`
  position: relative;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 20px;
  @media (max-width: 991px){
    
  }
`;

export const GateText = styled.div`
  position: relative;
  font-size: 16px;
  font-weight: 300;
  @media (max-width: 991px){
    
  }
`;

export const GateSubtitle = styled.div`
  position: relative;
  font-size: 16px;
  font-weight: 600;
  @media (max-width: 991px){
    
  }
`;


export const GateSep = styled.div`
  height: 1px;
  width: 20%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #aaa;
  
  @media (max-width: 991px){
    
  }
`;


export const Catchline = styled.h1`
  position: relative;
  font-size: 32px;
  font-weight: 400;
  color: #000;
  line-height: 34px;
  span{
    font-family: 'Dancing Script';
    font-weight: normal;
    font-size: 42px;
  }
  @media (max-width: 991px){
    position: relative;
    text-align: center;
    left: inherit;
    font-size: 28px;
    color: #fff;
    margin-top: 30px;
    position: relative;
    display: block;
  }
  @media (max-width: 767px){
    font-size: 28px;
  }
`;

export const LandingWrapper = styled.div`
  width: 450px;
  background-color: #fff;
  border-radius: 0px;
  color: #000;
  
  z-index: 19;
  margin: 0 auto;
  margin-top: 150px;
  position: absolute;
  left: 5%;
  img{
    width: 200px;
    height: auto;
    text-align: center;
  }
  @media (max-width: 991px){
    position: relative;
    margin-top: 20px;
    left: inherit;
    width: auto;
    background-color: transparent;
    color: #fff;
  }
  @media (max-width: 767px){
    margin-top: 0px;
  }
`;

export const Sep = styled.div`
  height: 1px;
  width: 20%;
  margin: 0px auto;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #ccc;
`;

export const LandingContent = styled.div`
  padding: 40px 40px;
  color: #000;
  font-weight: 300;
  text-align: center;
  @media (max-width: 991px){
    color: #fff;
  }
  @media (max-width: 767px){
    padding-top: 0px;
  }
`;

export const LandingTitle = styled.div`
  font-weight: 400;
  margin-top: 20px;
  font-size: 14px;
  color: #000;
  @media (max-width: 991px){
    color: #fff;
  }
`;

export const LandingMessage = styled.div<{active?: boolean}>`
  font-weight: 300;
  margin-top: 20px;
  font-size: 16px;
  color: #000;
  @media (max-width: 991px){
    color: #fff;
  }
`;

export const LandingFormContainer = styled.div`
  
`;


export const LandingForm = styled.div`
  padding: 40px;
  color: #000;
  padding-bottom: 20px;
  @media (max-width: 991px){
    button{
      width: 100%;
      
    }
  }
  @media (max-width: 767px){
      padding: 20px;
    }
  h3{
    margin-bottom: 20px;
  }
`;

export const ButtonGroup = styled.div`
  text-align: center;
    button{
      width: 100%;
      text-align: center;
      justify-content: center;
      margin: 0px;
      margin-top: 10px;
  }
`;

export const InputGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

export const InputField = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 14px 20px;
  height: 14px;
  width: 100%;
  display: block;
  position: relative;
  font-size: 16px;
  background-color: #fff;
  border: 1px solid #bbb;
  color: #000;
  @media (max-width: 991px){
    border-bottom: 1px solid #fff;
  }
  &::placeholder {
    color: #909090;
    @media (max-width: 991px){
      color: #fff;
    }
  }
  &.error{
    border: 1px solid red;
  }
  span{
    position: absolute;
    right: 5px;
  }
  &:focus{
    outline: inherit;
  }
`;
export const InputInfoIcon = styled.div<{active?: boolean}>`
  position: absolute;
  right: 20px;
  top: 12px;
  background-image: url(${icoInfo});
  background-size: 20px auto;
  background-position: center center;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  opacity: 1;
  @media (max-width: 991px){
    filter: invert(1);
    opacity: 1;
  }
  cursor: pointer;
  ${props => props.active && css`
    opacity: 1;
  `}
`;
export const InputInfo = styled.div<{active?: boolean}>`
  position: absolute;
  right: 0px;
  bottom: -70px;
  background-color: #909090;
  width: 200px;
  padding: 20px;
  border-radius: 10px;
  font-size: 11px;
  font-style: italic;
  color: #fff;
  z-index: 9999;
  -webkit-box-shadow: 0px 0px 56px 8px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 0px 56px 8px rgba(0,0,0,0.25);
  box-shadow: 0px 0px 56px 8px rgba(0,0,0,0.25);
  
  &:after{
    width: 0;
    height: 0;
    content: '';
    position: absolute;
    top: -10px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 15px solid #909090;
    right: 17px;
    margin-left: 15px;
  }
  display: none;
  ${props => props.active && css`
    display: block;
  `}
`;
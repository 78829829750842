import { colors } from './../../variables';
import styled, { css } from "styled-components";
import { RouteComponentProps, withRouter } from 'react-router-dom';

export const HeaderWrapper = styled.header`
  height: 50px;
  /* background-color: ${colors.back}; */
  position: relative;
  padding-left: 40px;
  padding-top: 40px;
  align-items: center;
  display: flex;
  @media (max-width: 991px){
    text-align: center;
    display: block;
    padding-left: 0px;
    padding-top: 15px;
  }
  @media (max-width: 767px){
    height: auto;
  }
`;

const _Logo = styled.img<RouteComponentProps>`
  width: 220px;
  height: auto;
  position: fixed;
  left: 20px;
  top: 20px;
  color: #000;
  text-transform: uppercase;
  font-style: italic;
  margin-right: 20px;
  @media (max-width: 991px){
    width: 200px;
    position: relative;
    top: inherit;
    z-index: 2;
    left: inherit;
    text-align: center;
    margin: 0 auto;
    top: 0px;
  }
  ${props => (
    props.match.path.startsWith('/message')
  ) && css`
    @media (max-width: 767px){
    filter: invert(1);
  }
    
  `
  }
  ${props => (
    props.match.path.startsWith('/test2') ||
    props.match.path.startsWith('/test')
  ) && css`
    @media (max-width: 991px){
      filter: invert(1);
    }
    
  `
  }
`;

const _Catchline = styled.h1<RouteComponentProps>`
  position: relative;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  position: fixed;
  left: 260px;
  top: 42px;
  display: none;
  span{
    font-family: 'Dancing Script';
    font-weight: normal;
    font-size: 24px;
  }
  @media (max-width: 991px){
    position: relative;
    text-align: center;
    left: inherit;
    font-size: 28px;
    margin-top: 0px;
    top: 10px;
    position: relative;
  }
  @media (max-width: 767px){
    font-size: 20px;
    padding: 0px 20px;
    top: 0px;
    margin-bottom: 30px;
  }

  ${props => (
    props.match.path.startsWith('/message')||
    props.match.path.startsWith('/end')||
    props.match.path.startsWith('/upload') ||
    props.match.path.startsWith('/preview')
  ) && css`
    display: block;
    
  `
  }

${props => (
    props.match.path.startsWith('/record')
  ) && css`
    @media (max-width: 767px){
    display: block;
  }
    
  `
  }

${props => (
    props.match.path.startsWith('/message')
  ) && css`
    @media (max-width: 767px){
    color: #000;
  }
    
  `
  }

${props => (
    props.match.path.startsWith('/preview') ||
    props.match.path.startsWith('/upload') ||
    props.match.path.startsWith('/end')
  ) && css`
    @media (max-width: 991px){
      display: none;
    }
    
  `
  }
`;

export const Catchline = withRouter(_Catchline);
export const Logo = withRouter(_Logo);
// const manifest = {
//    VM: true,
//   RESSELER: 145,
//   CODE_AUTO: true,
//   CODE_AUTO_CODE: '4DF62C6J' //'4DF59911',
// };

const manifest = {
  VM: false,
  ORD_TYPE: 1,
  PHONE_FORM: false,
  RESSELER: 132,
  CODE_AUTO: false,
  CODE_AUTO_CODE: '7E98F5CC' //'4DF59911',
};

export default manifest;

import styled, { css } from "styled-components";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import backgroundMobile from './images/background_mobile.jpg';
import backgroundDesktop from './images/background_desktop.jpg';

export const MainWrapper = styled.div`
  background-color: #fff;
  position: relative;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const _VideoBackground = styled.video<RouteComponentProps>`
    position: absolute;
    right: 0;
    top: 0;
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: auto;
    background-size: cover;
    object-fit: cover;
    @media (max-width: 991px){
      display: none;
    }
    ${props => (
      props.match.path.startsWith('/record') ||
      props.match.path.startsWith('/message')||
      props.match.path.startsWith('/preview')
    ) && css`
      display: none;
    `
    }
`;

const _PictureBackground = styled.div<RouteComponentProps>`
    position: fixed;
    left: 0;
    top: 0;
    min-width: 100%;
    width: 100%;
    height: 100%;
    background-size: cover;
    object-fit: cover;
    background-size: cover;
    background-position: center center;
    background-image: url(${backgroundDesktop});
    background-repeat: no-repeat;
    display: block;
    @media (max-width: 991px){
      background-position: bottom center;
      background-image: url(${backgroundMobile});
      display: block;
    }
    
    ${props => (
      props.match.path.startsWith('/message')
    ) && css`
      
      @media (max-width: 991px){
        background-image: inherit;
        background-color: #fff;
        display: block;
      }
    `
    }
`;


const _VideoBackgroundMobile = styled.div<RouteComponentProps>`
  position: absolute;
  background-size: cover;
  background-position: bottom center;
  background-color: #fff;
  background-image: url(${backgroundMobile});
  background-repeat: no-repeat;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
    height: auto;
  min-width: 100%;
    min-height: 100%;
  object-fit: cover;
  @media (min-width: 992px){
    display: none;
  }
  ${props => (
      props.match.path.startsWith('/message')
    ) && css`
      
      @media (max-width: 991px){
        background-image: inherit;
        background-color: #fff;
        display: block;
      }
    `
    }
`;

export const VideoBackgroundMobile = withRouter(_VideoBackgroundMobile);
export const PictureBackground = withRouter(_PictureBackground);
export const VideoBackground = withRouter(_VideoBackground);
import * as React from 'react';
import { MainWrapper, VideoBackground, VideoBackgroundMobile, PictureBackground } from './styled';
import videoMp4 from './images/video.mp4';
import poster from './images/poster.png';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
  } from "react-device-detect";


const Main:React.FunctionComponent = ({children}) => {
    return <MainWrapper>
        <BrowserView>
        <PictureBackground></PictureBackground>
        </BrowserView>
        <MobileView>
            <VideoBackgroundMobile></VideoBackgroundMobile>
        </MobileView>
        {children}
    </MainWrapper>;
}

export default Main;

import * as React from 'react';
import Layout from '../layout';
import { UploadWrapper, UploadLoader, UploadMessage, UploadTitle, UploadContent, UploadGif, UploadLoaderText } from './styled';
import imgLoading from './images/loading.gif';

import { Link, useHistory } from 'react-router-dom';

import {FormattedMessage} from 'react-intl';
import livstickApp, { progression } from '../../models/livstick';
import { Observer } from 'mobx-react';

const UploadPage:React.FunctionComponent = () => {
  const [pourcentage, setPourcentage] = React.useState<number>(0);

  const history = useHistory();

  React.useEffect(() => {
    window.scrollTo({ top: 0 });
    var _pourcentage = 0;
    console.log('test')
    livstickApp.pushMedia();
    var interval = setInterval(() => {
      console.log('pourcentage', pourcentage)
      if (_pourcentage < 100) {
        _pourcentage ++;
        setPourcentage(_pourcentage);
      }

      if (livstickApp.uploaded){
        history.push('/end');
        clearInterval(interval);
      }
    }, 500)
  }, [])

  return <Layout>
    <Observer>{() => 
    <UploadWrapper>
      <UploadContent>
        <UploadGif><img src={imgLoading} /></UploadGif>
        {progression.value == 90 ? <UploadLoaderText><FormattedMessage id="form_txt_almost"/></UploadLoaderText>: <UploadLoader>{progression.value}<sup>%</sup></UploadLoader>}
        <UploadTitle><FormattedMessage id="form_txt_wait"/></UploadTitle>
      </UploadContent>
    </UploadWrapper>
    }</Observer>
    
  </Layout>
}

export default UploadPage;
import * as React from 'react';
import { LanguagesWrapper, LanguagesSelectElement, LanguagesSelect, LanguagesOption } from './styled';

import livstickApp from '../../models/livstick';

const Languages: React.FunctionComponent = () => {
  const language = navigator.language.split(/[-_]/)[0];
  return <>
  </>;
}

export default Languages;

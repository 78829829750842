import * as React from 'react';
import { HeaderWrapper, Logo, Catchline } from './styled';
import logo from './images/logo.png';
import {Helmet} from "react-helmet";
import {FormattedMessage, injectIntl, useIntl} from 'react-intl';

const Header:React.FunctionComponent = () => {
    const intl = useIntl();
    return <HeaderWrapper>
        <Logo src={logo} />
        <Catchline dangerouslySetInnerHTML={{__html: intl.formatMessage({id: "global_txt_title"})}}></Catchline>
    </HeaderWrapper>;
}

export default Header;